import { useQuery } from "@tanstack/react-query";
import { getProcessflowStages } from "../../jason-proof-of-concept/processflow-stages/actions/get-processflow-stages";

type Params = {
    authToken: string;
    processflowGroup: number;
};

const getProcessflowStagesQueryKey = ({ processflowGroup, authToken }: Params) => {
    return ["processflow-stages", { processflowGroup, authToken }];
};

export const useProcessflowStages = ({ processflowGroup, authToken }: Params) => {
    const query = useQuery({
        queryKey: getProcessflowStagesQueryKey({ processflowGroup, authToken }),
        queryFn: async () => {
            const processflowStages = await getProcessflowStages({ processflowGroup, authToken });
            return processflowStages;
        },
    });
    return query;
};
