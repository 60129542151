import { useQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";
import { sumBy } from "lodash";
import { HTMLAttributes, ReactNode, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Currency } from "../billing/components";
import {
    affiliateCommissionMultiplier,
    getEstimatedPayoutFromData,
} from "../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getUsers } from "../jason-proof-of-concept/users/actions/get-users";
import { getDataFromProgressData } from "../jason-proof-of-concept/wizard/wizard-container";
import { getAuthTokenNoThrow } from "../services/auth-header";
import { TBody, THead, Table, Td, Th, Tr } from "../layout/table";
import ButtonNeoGen from "../layout/button-neogen";
import { CompanyStatus } from "../companies/domain/company";
import { timeAgo } from "../sections/users/cards/calls-list";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { sortUsers } from "../sections/utilities/sortUsers";
import { Select } from "../layout/form/select-input";
import PageDescription from "../layout/page-description";
import { useUserCompanies } from "../companies/hooks/use-user-companies";
import { useCompanies } from "../companies/hooks/use-companies";
import { useProcessflowProgresses } from "../companies/hooks/use-processflow-progresses";
import { useProcessflowStages } from "../companies/hooks/use-processflow-stages";

export const Section = ({
    children,
    className,
    style,
}: {
    children: ReactNode;
    className?: string;
    style?: HTMLAttributes<HTMLDivElement>["style"];
}) => {
    return (
        <div
            style={style}
            className={`bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow p-5 ${
                className || ""
            }`}
        >
            {children}
        </div>
    );
};

export const Stat = ({ title, value }: { title: ReactNode; value: ReactNode }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="text-sm font-medium text-gray-400">{title}</div>
            <div className="text-2xl font-semibold text-gray-500 dark:text-green-500">{value}</div>
        </div>
    );
};

export const AffiliateBillingPage = () => {
    const now = useMemo(() => new Date(), []);
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const navigate = useNavigate();
    const { search } = useLocation();
    const queryParamUserId = useMemo(() => new URLSearchParams(search), [search]);
    const userId = queryParamUserId.get("userId");
    const [affiliateUserId, setAffiliateUserId] = useState(userId);

    const userCompanies = useUserCompanies({
        authToken,
        filters: { where: { roleGroupId: 7, ercUserId: affiliateUserId } },
    });

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: (userCompanies.data || []).map((uc) => uc.companyId) } } },
    });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const affiliateUsersQuery = useQuery(["affiliates", { authToken }], async () => {
        const users = await getUsers({
            authToken,
            filters: {
                where: {
                    role: "Affiliate",
                },
            },
        });
        return users;
    });

    const progressesQuery = useProcessflowProgresses({
        authToken,
        filters: { userIds: companies.map((c) => c.ownedById), group: 7 },
    });
    const processflowProgresses = useMemo(() => progressesQuery.data || [], [progressesQuery.data]);

    const progressflowProgressStages = useProcessflowStages({ authToken, processflowGroup: 7 });

    const rows = useMemo(
        () =>
            companies.map((c) => {
                const processflowProgress = processflowProgresses.find((p) => p.userId === c.ownedById);
                const dealValue = processflowProgress?.data
                    ? getEstimatedPayoutFromData({
                          data: getDataFromProgressData(processflowProgress.data as any),
                      })
                    : 0;
                const estimatedCommission = dealValue * affiliateCommissionMultiplier;
                const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
                return {
                    ownedByUser: c.ownedBy,
                    processflowProgress: processflowProgress,
                    progressPercentage,
                    lastActive: subDays(now, 2),
                    dealValue,
                    estimatedCommission,
                    company: c,
                };
            }),
        [companies, now, processflowProgresses],
    );

    const dealsValueInProgress = useMemo(() => sumBy(rows, (row) => row.dealValue || 0), [rows]);
    const expectedCommission = useMemo(() => sumBy(rows, (row) => row.estimatedCommission || 0), [rows]);
    const outstandingCommission = useMemo(() => sumBy(rows, (row) => row.estimatedCommission || 0), [rows]);
    const dealsUnderCPAReview = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.currentStage
                    ? [25, 65, 81, 52, 69, 85].includes(row.processflowProgress?.currentStage)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows],
    );

    const dealsUnderIRSReview = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.currentStage
                    ? [28, 60, 76].includes(row.processflowProgress?.currentStage)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows],
    );

    const dealsCompleted = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.currentStage
                    ? [59, 91].includes(row.processflowProgress?.currentStage)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows],
    );

    const paidCommission = useMemo(
        () => sumBy(rows, (row) => (row.progressPercentage === 100 ? row.dealValue : 0)),
        [rows],
    );

    const activeRows = useMemo(() => rows.filter((row) => row.company?.status === CompanyStatus.active), [rows]);
    const cpaRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.currentStage
                    ? [25, 65, 81, 52, 69, 85].includes(row.processflowProgress?.currentStage)
                    : undefined,
            ),
        [rows],
    );

    const irsRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.currentStage
                    ? [28, 60, 76].includes(row.processflowProgress?.currentStage)
                    : undefined,
            ),
        [rows],
    );

    const completedRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.currentStage
                    ? [59, 91].includes(row.processflowProgress?.currentStage)
                    : row.progressPercentage === 100,
            ),
        [rows],
    );

    const archivedRows = useMemo(() => rows.filter((row) => row.company?.status === CompanyStatus.archived), [rows]);

    const affiliateOptions = useMemo(
        () =>
            sortUsers((affiliateUsersQuery.data || []) as ClearERCUser[]).map((affiliate) => ({
                value: affiliate.id || "",
                label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
            })),
        [affiliateUsersQuery.data],
    );
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <PageDescription title="Deals">
                    <div className="w-72">
                        <Select
                            label="Viewing deals for affiliate:"
                            options={affiliateOptions}
                            value={affiliateOptions.find((option) => option.value === affiliateUserId)?.value || null}
                            onChange={(value) => setAffiliateUserId(value as string)}
                        />
                    </div>
                </PageDescription>

                <div
                    style={{ display: "flex", flexDirection: "row", gap: 20, flex: 1, justifyContent: "space-between" }}
                >
                    <Section
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            gridTemplateColumns: "1fr 1fr",
                            gridAutoRows: "70px",
                            rowGap: 0,
                            columnGap: 35,
                        }}
                        className="w-full"
                    >
                        <div style={{ gridRow: 1, gridColumn: "1 / 2" }}>
                            <Stat title="Deals in progress value" value={<Currency amount={dealsValueInProgress} />} />
                        </div>
                        <div style={{ gridRow: 1, gridColumn: "2 / 3" }}>
                            <Stat title="Expected total commission" value={<Currency amount={expectedCommission} />} />
                        </div>
                        <div style={{ gridRow: 2, gridColumn: "1 / 3" }}>
                            <Stat title="Under CPA review" value={<Currency amount={dealsUnderCPAReview} />} />
                        </div>
                        <div style={{ gridRow: 3, gridColumn: "1 / 3" }}>
                            <Stat title="Under IRS review" value={<Currency amount={dealsUnderIRSReview} />} />
                        </div>
                        <div style={{ gridRow: 2, gridColumn: "2 / 3" }}>
                            <Stat title="Outstanding commission" value={<Currency amount={0} />} />
                        </div>
                        <div style={{ gridRow: 3, gridColumn: "2 / 3" }}>
                            <Stat title="Paid commission" value={<Currency amount={0} />} />
                        </div>
                    </Section>
                    {/* <Section className="w-1/3">In-progress pipeline value graph</Section>
                    <Section className="w-1/3">Commission payouts graph</Section> */}
                </div>
                <h2>Deals in progress</h2>
                <Table>
                    <THead>
                        <Tr>
                            <Th>Company</Th>
                            <Th>Created at</Th>
                            <Th>Owner</Th>
                            <Th>Progress status</Th>
                            <Th>Progress</Th>
                            <Th>Deal value</Th>
                            <Th>Estimated commission</Th>
                            <Th />
                        </Tr>
                    </THead>
                    <TBody>
                        {activeRows.length === 0 && (
                            <Tr>
                                <Td colSpan={8} style={{ textAlign: "center" }}>
                                    No active deals
                                </Td>
                            </Tr>
                        )}
                        {activeRows.length > 0 &&
                            activeRows.map((row) => {
                                return (
                                    <Tr key={row.company.id}>
                                        <Td>{row.company?.name || "-"}</Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <div className="flex flex-col">
                                                {row.company?.createdAt?.toDateString()}
                                                <span style={{ color: "gray" }}>
                                                    {row.company?.createdAt
                                                        ? timeAgo(row.company.createdAt).toLocaleString()
                                                        : "-"}
                                                </span>
                                            </div>
                                        </Td>
                                        <Td>
                                            {[row.ownedByUser?.firstName, row.ownedByUser?.lastName].join(" ") || "-"}
                                        </Td>
                                        <Td>
                                            {progressflowProgressStages.data?.find(
                                                (p) => p.id === row.processflowProgress?.currentStage,
                                            )?.name || "-"}
                                        </Td>
                                        <Td>{row.progressPercentage}%</Td>
                                        <Td>
                                            <Currency amount={row.dealValue} />
                                        </Td>
                                        <Td>
                                            <Currency amount={row.estimatedCommission} />
                                        </Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            {row.company?.id && (
                                                <ButtonNeoGen
                                                    size="sm"
                                                    onClick={() => {
                                                        navigate(`/companies/${row.company?.id}`);
                                                    }}
                                                >
                                                    Open company
                                                </ButtonNeoGen>
                                            )}
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </TBody>
                </Table>
                <h2>Under CPA review</h2>
                <Table>
                    <THead>
                        <Tr>
                            <Th>Company</Th>
                            <Th>Filed at</Th>
                            <Th>Fee structure</Th>
                            <Th>Estimated deal value</Th>
                            <Th>Estimated commission</Th>
                            <Th />
                        </Tr>
                    </THead>
                    <TBody>
                        {cpaRows.length === 0 && (
                            <Tr>
                                <Td colSpan={6} style={{ textAlign: "center" }}>
                                    No deals under CPA review
                                </Td>
                            </Tr>
                        )}
                        {cpaRows.length > 0 &&
                            cpaRows.map((row) => (
                                <Tr key={row.company.id}>
                                    <Td>{row.company?.name || "-"}</Td>
                                    <Td>Coming soon</Td>
                                    <Td>
                                        Deposit fee %: {row.company.depositFeePercent || 0} <br />
                                        Later fee %: {row.company.laterFeePercent || 0}
                                    </Td>
                                    <Td>
                                        <Currency amount={row.dealValue} />
                                    </Td>
                                    <Td>
                                        <Currency amount={row.estimatedCommission} />
                                    </Td>
                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        {row.company?.id && (
                                            <ButtonNeoGen
                                                size="sm"
                                                onClick={() => {
                                                    navigate(`/companies/${row.company?.id}`);
                                                }}
                                            >
                                                Open company
                                            </ButtonNeoGen>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                    </TBody>
                </Table>
                <h2>Under IRS review</h2>
                <Table>
                    <THead>
                        <Tr>
                            <Th>Company</Th>
                            <Th>Filed at</Th>
                            <Th>Fee structure</Th>
                            <Th>Estimated deal value</Th>
                            <Th>Estimated commission</Th>
                            <Th />
                        </Tr>
                    </THead>
                    <TBody>
                        {irsRows.length === 0 && (
                            <Tr>
                                <Td colSpan={6} style={{ textAlign: "center" }}>
                                    No deals under IRS review
                                </Td>
                            </Tr>
                        )}
                        {irsRows.length > 0 &&
                            irsRows.map((row) => (
                                <Tr key={row.company.id}>
                                    <Td>{row.company?.name || "-"}</Td>
                                    <Td>Coming soon</Td>
                                    <Td>
                                        Deposit fee %: {row.company.depositFeePercent || 0} <br />
                                        Later fee %: {row.company.laterFeePercent || 0}
                                    </Td>
                                    <Td>
                                        <Currency amount={row.dealValue} />
                                    </Td>
                                    <Td>
                                        <Currency amount={row.estimatedCommission} />
                                    </Td>
                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        {row.company?.id && (
                                            <ButtonNeoGen
                                                size="sm"
                                                onClick={() => {
                                                    navigate(`/companies/${row.company?.id}`);
                                                }}
                                            >
                                                Open company
                                            </ButtonNeoGen>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                    </TBody>
                </Table>
                <h2>Completed deals</h2>
                <Table>
                    <THead>
                        <Tr>
                            <Th>Company</Th>
                            <Th>Completed at</Th>
                            <Th>Deal value</Th>
                            <Th>Commission paid</Th>
                            <Th>Commission outstanding</Th>
                            <Th />
                        </Tr>
                    </THead>
                    <TBody>
                        {completedRows.length === 0 && (
                            <Tr>
                                <Td colSpan={6} style={{ textAlign: "center" }}>
                                    No completed deals
                                </Td>
                            </Tr>
                        )}
                        {completedRows.length > 0 &&
                            completedRows.map((row) => (
                                <Tr key={row.company.id}>
                                    <Td>{row.company?.name || "-"}</Td>
                                    <Td>Coming soon</Td>
                                    <Td>
                                        <Currency amount={row.dealValue} />
                                    </Td>
                                    <Td>Coming soon</Td>
                                    <Td>Coming soon</Td>
                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        {row.company?.id && (
                                            <ButtonNeoGen
                                                size="sm"
                                                onClick={() => {
                                                    navigate(`/companies/${row.company?.id}`);
                                                }}
                                            >
                                                Open company
                                            </ButtonNeoGen>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                    </TBody>
                </Table>
                <div className="mb-5">
                    <h2>Archived deals</h2>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Company</Th>
                                <Th>Owner</Th>
                                <Th>Progress</Th>
                                <Th>Deal value</Th>
                                <Th>Estimated commission</Th>
                                <Th />
                            </Tr>
                        </THead>
                        <TBody>
                            {archivedRows.length === 0 && (
                                <Tr>
                                    <Td colSpan={6} style={{ textAlign: "center" }}>
                                        No archived deals
                                    </Td>
                                </Tr>
                            )}
                            {archivedRows.length > 0 &&
                                archivedRows.map((row) => (
                                    <Tr key={row.company.id}>
                                        <Td>{row.company?.name || "-"}</Td>
                                        <Td>
                                            {[row.ownedByUser?.firstName, row.ownedByUser?.lastName].join(" ") || "-"}
                                        </Td>
                                        <Td>{row.progressPercentage}%</Td>
                                        <Td>
                                            <Currency amount={row.dealValue} />
                                        </Td>
                                        <Td>
                                            <Currency amount={row.estimatedCommission} />
                                        </Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            {row.company?.id && (
                                                <ButtonNeoGen
                                                    size="sm"
                                                    onClick={() => {
                                                        navigate(`/companies/${row.company?.id}`);
                                                    }}
                                                >
                                                    Open company
                                                </ButtonNeoGen>
                                            )}
                                        </Td>
                                    </Tr>
                                ))}
                        </TBody>
                    </Table>
                </div>
            </div>
        </div>
    );
};
